import axios from '@/api/api-request'

const projectUrl = '/webNoticeIndustry'

// 新闻资讯-- 分页查询
export const list = (data) => {
  return axios.request({
    url: projectUrl + '/list.do',
    method: 'post',
    data: data
  })
}
// 新闻资讯-- 分页查询
export const firstList = (data) => {
  return axios.request({
    url: projectUrl + '/firstList.do',
    method: 'post',
    data: data
  })
}

// 新闻资讯-- 获取详情
export const getInfo = (data) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    data: data
  })
}
