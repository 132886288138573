<template>
  <div class="kuang_ping_nwes">
    <div class="px1200 nimen_news">
      <div class="shouye_biapt clas_news_bt">
        <div class="mingz_news"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/news">新闻资讯</my-link></span></div>
      </div>
      <div class="chanp_biapt">
        <h2>新闻资讯</h2>
      </div>
      <div class="news_list_ziye">
        <ul>
          <li v-for="item in notices" :key="item.id">
            <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode"><img :src="httpPath + item.imgUrl" alt=""></my-link>
            <div class="text">
              <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode"><h2 :title="item.title">{{ item.title }}</h2></my-link>
              <p :title="item.description">{{ item.description }}</p>
              <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode" class="xiangq">查看详情>></my-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageParam" @callFather="callFather"></pagination>
    </div>
  </div>

<!--  <div class="wrap-lg">-->
<!--      <div>-->
<!--        <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">新闻资讯</span></div>-->
<!--      </div>-->
<!--      <div class="list-news">-->
<!--        <div class="news-box">-->
<!--          <div v-for="item in notices" :key="item.id" class="news-box-item">-->
<!--            <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode">-->
<!--              <div class="box-item-center">-->
<!--                <div class="box-item-img">-->
<!--                  <img :src="httpPath + item.imgUrl" style=" display: block; width: 100%; height: 100%"/>-->
<!--                </div>-->
<!--                <div class="box-item-text">-->
<!--                  <div class="box-title beyond-hide">-->
<!--                    <span :title="item.title">{{ item.title }}</span>-->
<!--                  </div>-->
<!--                  <div class="box-desc multi-hide">-->
<!--                    <span :title="item.description">{{ item.description }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </my-link>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
</template>

<script>
import { list as industryList } from '@/api/news/industry'
export default {
  name: 'index',
  data () {
    return {
      httpPath: '',
      categoryCode: '2',
      searchText: '',
      notices: [],
      loading: false,
      pageParam: {
        currentPage: 1,
        pageSize: 10,
        total: 10,
        pageSizes: [10, 20, 30, 50, 100]
      }
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.mockData()
  },
  methods: {
    // 获取客户列表
    mockData: function () {
      this.loading = true
      let param = {}
      param.categoryCode = this.categoryCode
      param.keyWord = this.searchText
      param.page = {
        pageNum: this.pageParam.currentPage,
        pageSize: this.pageParam.pageSize,
        orderBy: 'createTime'
      }
      if (this.categoryCode === '2') {
        this.getIndustryList(param)
      }
    },
    getIndustryList (param) {
      industryList(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.notices = result.bizData
          // 分页赋值
          this.pageParam.total = result.total
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    },
    callFather (parm) {
      this.pageParam.currentPage = parm.currentPage
      this.pageParam.pageSize = parm.pageSize
      this.mockData()
    }
  }
}
</script>

<style lang="scss" scoped>
.list-news {
  display: inline-flex;

  .news-box {
    width: 1226px;

    .news-box-item {
      background: #4f080e;
      float: left;
      margin-right: 50px;
      list-style: none;
      height: 150px;
      width: 550px;
      margin-top: 30px;
      text-align: left;
      border: 1px solid #f6b95e;

      .box-item-center {
        display: inline-flex;
        width: 100%;
        height: 100%;

        .box-item-img {
          width: 150px;
          height: 100%;
        }

        .box-item-text {
          width: 370px;
          margin: 20px auto auto 20px;

          .box-title {
            font-size: 18px;
            line-height: 30px;
            color: #f6b95e;
          }

          .box-desc {
            font-size: 14px;
            line-height: 20px;
            color: #f6b95e;
          }
        }
      }
    }
  }

}
.clas_news_bt .mingz_news {
  border-bottom: 0;
}
</style>
